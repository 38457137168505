import { useState } from 'react'

import InfoBox from '../../../components/app/alertBox/infoBox'
import { Button, DialogModal } from '../../../components/common'
import ListingHeader from '../../../components/common/ListingTiles'
import { useSnackbarManager } from '../../../components/common/snackbar'
import { exportServiceWiseOrderReport } from './api'

const ServiceWiseOrderSummary = () => {
  const [exportPopup, setExportPopup] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()

  const [loader, setloader] = useState(false)

  const basicData = {
    title: 'Service Wise Order Services Report',
    icon: 'user',
  }

  const handleDownload = async () => {
    setloader(true)
    try {
      const response = await exportServiceWiseOrderReport({})
      if (response.status == 200) {
        setloader(false)
        enqueueSnackbar(response?.data, { variant: 'success' })
      } else {
        setloader(false)
        enqueueSnackbar('Failed to send data', { variant: 'error' })
      }
    } catch (err: any) {
      setloader(false)
      enqueueSnackbar(err?.response?.data?.data || 'Failed to send data', {
        variant: 'error',
      })
    }
  }

  const handleExport = async () => {
    setExportPopup(true)
  }

  const handleExportSubmit = () => {
    setExportPopup(false)
    handleDownload()
  }

  const handleExportCancel = () => {
    setExportPopup(false)
  }
  return (
    <div className="con">
      <DialogModal
        isOpen={exportPopup}
        onClose={() => handleExportCancel()}
        title={'Are you sure?'}
        onSubmit={() => handleExportSubmit()}
        secondaryAction={() => handleExportCancel()}
        secondaryActionLabel="Cancel"
        actionLabel={'Send'}
        actionLoader={loader}
        className="z-50"
        body={
          <InfoBox content="Are you sure you want to generate the service wise order services report?" />
        }
      />
      <ListingHeader data={basicData} />

      <div
        className="gap-2 mt-10 ml-5"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <Button
          label="Generate Report"
          icon="upload"
          outlined={true}
          onClick={handleExport}
          // disabled={}
          isLoading={loader}
        />
      </div>
    </div>
  )
}

export default ServiceWiseOrderSummary
