import { useEffect, useState } from 'react'
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom'

import { TabItemProps } from '../../../common/types'
import { TabContainer } from '../../../components/common'
import DetailHeader from '../../../components/common/DetailTiles'
import { blockActionsbyStatus } from '../../../configs/permissionGate'
import { router_config } from '../../../configs/route.config'
import { useAppStore } from '../../../store/appStore'
import { useClearFilter } from '../../../store/filterSore/clearStore'
import { getVendorDetails } from '../api'
import { checkPermission } from '../permission'

const VendorDetails = () => {
  const navigate = useNavigate()

  const [edit] = useState(false)
  const [data, setData] = useState<any>()
  const { setIsLoading } = useAppStore()

  const params = useParams()
  const tabData = [
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_SUMMARY.label,
      id: 'SYSTEM_SETTINGS_VENDOR_SUMMARY',
    },
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_EMPLOYEES.label,
      id: 'SYSTEM_SETTINGS_VENDOR_EMPLOYEES',
    },
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_TEAM.label,
      id: 'SYSTEM_SETTINGS_VENDOR_TEAM',
    },
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_ROLE.label,
      id: 'SYSTEM_SETTINGS_VENDOR_ROLE',
    },
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_SERVICE.label,
      id: 'SYSTEM_SETTINGS_VENDOR_SERVICE',
    },
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT.label,
      id: 'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT',
    },

    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_PAYMENT_GATEWAY.label,
      id: 'SYSTEM_SETTINGS_VENDOR_PAYMENT_GATEWAY',
    },
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_CREDIT_BANK_ACCOUNT.label,
      id: 'SYSTEM_SETTINGS_VENDOR_CREDIT_BANK_ACCOUNT',
    },
  ]
  const [activeTab, setActiveTab] = useState<string>(
    'SYSTEM_SETTINGS_FRANCHISEE_SUMMARY'
  )
  const handleClear = useClearFilter()

  const haandleTabChange = (selected: TabItemProps) => {
    handleClear()
    navigate(
      generatePath(router_config[selected.id]?.path as string, {
        id: params.id,
      })
    )
  }
  useEffect(() => {
    if (params.id) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const getData = async () => {
    setIsLoading(true)
    const { data } = await getVendorDetails(params.id)

    setIsLoading(false)
    setData(data)
  }

  const chipdata = [
    { id: 1, status: 'Normal' },
    { id: 2, status: 'Normal' },
    { id: 3, status: 'Normal' },
  ]
  const tiledata = [
    {
      id: 1,
      tiles: [
        {
          icon: 'email',
          value: data?.email ?? '- -',
          id: 1,
        },
        {
          icon: 'phone',
          value: data?.phone ?? '- -',
          id: 2,
        },
        {
          label: 'Status :',
          value: data?.status ? 'Active' : 'Inactive',
          id: 3,
        },
      ],
    },
  ]
  const basicData = {
    title: `${data?.vendor_name ?? '--'} (${data?.unique_id ?? '--'})`,
    image: data?.account?.profile_image,
  }

  const contextData = {
    setActiveTab: (id: string) => setActiveTab(id),
    edit: edit,
    vendor: params.id,
    detailsData: data,
    handleCallBack: getData,
    handleParentCallback: getData,
  }
  const handleDisable = () => {
    return (
      blockActionsbyStatus(data?.order_status?.code) ||
      !checkPermission('change')
    )
  }
  return (
    <div>
      <div>
        <DetailHeader
          tiledata={tiledata}
          chipdata={chipdata}
          disabled={handleDisable()}
          data={basicData}
        />
        {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      </div>
      <div className="flex gap-4">
        <TabContainer
          data={tabData}
          activeTab={activeTab}
          onClick={haandleTabChange}
        >
          <Outlet context={contextData} />
        </TabContainer>
      </div>
    </div>
  )
}

export default VendorDetails
