import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { getCountry, getState } from '../../../../apis/common.apis'
import FormBuilder from '../../../../components/app/formBuilder'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import { useUpdateLead } from '../../api'
import { handleLeadAddressInfoInput, handleOnInitData } from './leadUtils'
import { leadAddressInfo, LeadAddressInfo } from './schema'

type Props = {
  data?: any
  id?: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  disabled?: boolean
}
const AddressInformation = (props: Props) => {
  // const { enqueueSnackbar } = useSnackbarManager()
  const { data, id, handleRefresh, handleEditButton, disabled, editFlag } =
    props
  const [isEditing, setIsEditing] = useState(false)

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const onInit = () => {
    const onInitData = handleOnInitData(data)
    return onInitData
  }
  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const methods = useForm<LeadAddressInfo>({
    resolver: zodResolver(leadAddressInfo),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })
  const { country } = methods.watch()
  const getCountryData = async (value: any) => {
    const { data } = await getCountry({ search: value, type: 'dropdown' })
    return data?.results?.map((item: any) => ({
      ...item,
      id: item.name,
      country_name: item.name,
    }))
  }
  const getStateData = async (value: any) => {
    const { data } = await getState({
      search: value,
      country__name: country,
    })
    return data?.results?.map((item: any) => ({
      ...item,
      state_name: item.state_display_name,
    }))
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    value: string
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    value,
  })

  const formBuilderProps = [
    textField(
      'address_1',
      'Address Line 1',
      'Enter Address Line 1',
      false,
      data?.lead_address?.address_1
    ),
    textField(
      'address_2',
      'Address Line 2',
      'Enter Address Line 2',
      false,
      data?.lead_address?.address_2
    ),
    {
      name: 'country',
      label: 'Country',
      required: false,
      getData: getCountryData,
      async: true,
      id: 'country',
      descId: 'code',
      initialLoad: false,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Select Country',
      value: data?.lead_address?.country,
      handleCallBack: () => {
        methods.setValue('state', '')
        methods.setValue('stateName', '')
      },
    },
    {
      name: 'state',
      label: 'State',
      required: true,
      getData: getStateData,
      async: true,
      id: 'state',
      descId: 'state', //state
      initialLoad: false,

      disabled: !country || country === '',
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Select state',
      value: data?.lead_address?.state,
    },
    textField('city', 'City', 'Enter City', false, data?.lead_address?.city),

    textField(
      'pin_code',
      'Pincode',
      'Enter Pincode',
      false,
      data?.lead_address?.pin_code
    ),
  ]

  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    setIsEditing(!isEditing)
  }
  const checkforErrors = () => {
    // if (
    //   methods?.formState?.errors?.next_followup_date ||
    //   methods.formState.errors.target_date
    // ) {
    //   if (
    //     methods?.formState?.errors?.next_followup_date &&
    //     methods.formState.errors.target_date
    //   ) {
    //     enqueueSnackbar(
    //       'Target Date and Next Followup Date of Lead Information is required',
    //       {
    //         variant: 'error',
    //       }
    //     )
    //   } else if (methods?.formState?.errors?.next_followup_date) {
    //     enqueueSnackbar('Next Followup Date of Lead Information is required', {
    //       variant: 'error',
    //     })
    //   } else {
    //     enqueueSnackbar('Target Date of Lead Information is required', {
    //       variant: 'error',
    //     })
    //   }
    // }
  }
  useEffect(() => {
    checkforErrors()
  }, [methods.watch(), methods.formState.errors])
  const { mutate, isLoading } = useUpdateLead(handleSubmission)
  const onSubmit = (data: LeadAddressInfo) => {
    const input = handleLeadAddressInfoInput(data)
    mutate({
      input: { ...input, type: 'address_info' },
      id: id ? id : '',
    })
  }
  const { handleSubmit } = methods
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
    // handleRefresh()
  }
  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder
              data={formBuilderProps}
              edit={true}
              // schemaName={'lead_basic_info_update'}
            />
          </FormProvider>
        ) : (
          formBuilderProps.map((data) => (
            <FormFieldView
              value={data.value}
              type={data?.type}
              // required={data.required}
              label={data.label}
              key={data?.id}
            />
          ))
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="location-pin" />
          <h4 className=" font-bold text-primaryText">Address Information</h4>
        </div>
        <div className="iconBlack cursor-pointer">
          {isEditing ? (
            <div className="flex gap-2">
              <div>
                <Button
                  onClick={handleCancel}
                  size="xs"
                  label="Cancel"
                  outlined={true}
                />
              </div>
              <div>
                <Button
                  size="xs"
                  label="Save"
                  outlined={false}
                  isLoading={isLoading}
                  onClick={handleSubmit((data) => onSubmit(data))}
                />
              </div>
            </div>
          ) : !editFlag && !disabled ? (
            <Icons onClick={handleEditClick} name="edit" />
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">{renderFields()}</div>
    </div>
  )
}

export default AddressInformation
