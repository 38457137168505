import { z } from 'zod'

import isValidIfscCode from '../../../../../utilities/isValidIfscCode'
import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'

export const bankAccountFullSchema = z.object({
  account_name: z
    .string({ invalid_type_error: 'Name In Account is required.' })
    .min(1, { message: 'Name In Account is required.' })
    .max(45, { message: 'Name In Account length exceeds' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  account_number: z
    .string({ invalid_type_error: 'Account Number is required.' })
    .min(1, { message: 'Account Number is required.' })
    .regex(/^\d+$/, {
      message: 'Must contain only numbers',
    }),
  bank_name: z
    .string({ invalid_type_error: 'Bank Name is required.' })
    .min(1, { message: 'Bank Name is required.' })
    .max(45, { message: 'Bank Name length exceeds' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  branch_name: z
    .string({ invalid_type_error: 'Branch Name is required.' })
    .min(1, { message: 'Branch Name is required.' })
    .max(45, { message: 'Branch Name length exceeds' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  ifsc_code: z
    .string({ invalid_type_error: 'IFSC Code is required.' })
    .min(1, { message: 'IFSC Code is required.' })
    .max(45, { message: 'IFSC Code length exceeds' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .refine(isValidIfscCode, {
      message: 'Invalid IFSC Code',
    }),
  account_type_name: z
    .string({
      invalid_type_error: 'Account type is required.',
    })
    .min(1, { message: 'Account type is required.' }),
  account_type: z
    .string({ invalid_type_error: 'Account type is required.' })
    .min(1, { message: 'Account type is required.' }),
  display_name: z
    .string({ invalid_type_error: 'Display Name is required.' })
    .min(1, { message: 'Display Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),

  /////////////////////////////////////////////////////////////////
  professional_fee: z.boolean().optional().nullable(),
  vendor_fee: z.boolean().optional().nullable(),
  govt_fee: z.boolean().optional().nullable(),
  gst: z.boolean().optional().nullable(),
  late_fees: z.boolean().optional().nullable(),
  govt_taxes: z.boolean().optional().nullable(),
  penalty: z.boolean().optional().nullable(),

  /////////////////////////////////////////////////////////////////
})

export const bankAccountSchema = bankAccountFullSchema
  .omit({
    professional_fee: true,
    vendor_fee: true,
    govt_fee: true,
    gst: true,
    late_fees: true,
    govt_taxes: true,
    penalty: true,
  })
  .extend({
    display_name: z
      .string({ invalid_type_error: 'Display Name is required.' })
      .nullable()
      .optional(),
  })

export type AddBankAccountSchema = z.infer<typeof bankAccountSchema>
export type AddCreditBankAccountSchema = z.infer<typeof bankAccountFullSchema>
