import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../components/app/formBuilder/index'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import { getPaymentList, useUpdatePaymentGateway } from '../../api'
import { paymentSchema, PaymentSchema } from '../../create/schema'

type Props = {
  data: any
  id: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  hasPermission?: boolean
}

const BasicInformation = (props: Props) => {
  const { data, id, handleRefresh, handleEditButton, editFlag, hasPermission } =
    props

  const [isEditing, setIsEditing] = useState(false)
  const [gateway, setGateway] = useState<any>()

  const getPayment = async (value: any) => {
    const { data } = await getPaymentList({
      search: value ?? '',
      type: 'dropdown',
    })
    setGateway(data)
    return data?.map((item: any) => ({
      ...item,
      name: `${item.name}`,
    }))
  }
  const onInit = () => {
    return {
      default_payment_gateway:
        data?.payment_gateway?.default_payment_gateway ?? undefined,
      default_payment_gateway_name:
        gateway?.find(
          (item: any) =>
            item.code === data?.payment_gateway?.default_payment_gateway
        )?.name ?? undefined,
      razorpay_public_key:
        data?.payment_gateway?.razorpay?.razorpay_public_key ?? null,
      razorpay_secret_key:
        data.payment_gateway?.razorpay?.razorpay_secret_key ?? null,
      razorpay_redirection_url:
        data.payment_gateway?.razorpay?.razorpay_redirection_url ?? null,
      easebuzz_merchant_key:
        data?.payment_gateway?.easebuzz?.easebuzz_merchant_key ?? null,
      easebuzz_salt: data.payment_gateway?.easebuzz?.easebuzz_salt ?? null,
      easebuzz_redirection_url:
        data.payment_gateway?.easebuzz?.easebuzz_redirection_url ?? null,
      razorpay_split_payment_enabled:
        data?.payment_gateway?.razorpay?.razorpay_split_payment_enabled ?? null,
      easebuzz_split_payment_enabled:
        data?.payment_gateway?.easebuzz?.easebuzz_split_payment_enabled ?? null,
    }
  }
  const methods = useForm<PaymentSchema>({
    resolver: zodResolver(paymentSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })

  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    getPayment('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { default_payment_gateway } = methods.watch()

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    value: string,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    value,
    ...(required ? { required: true } : {}),
  })

  const formBuilderProps = [
    {
      name: 'default_payment_gateway_name',
      label: 'Payment gateway',
      required: true,
      getData: getPayment,
      async: false,
      id: 'default_payment_gateway',
      descId: 'code',
      initialLoad: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter Payment gateway',
      value: data?.payment_gateway?.default_payment_gateway
        ? `${
            gateway &&
            gateway?.find(
              (item: any) =>
                item.code === data?.payment_gateway?.default_payment_gateway
            )?.name
          } (${data?.payment_gateway?.default_payment_gateway})`
        : '--',
      spacing: 12,
    },
    {
      ...textField(
        'razorpay_public_key',
        'Razorpay Public Key',
        'Enter Razorpay Public Key',
        data?.payment_gateway?.razorpay?.razorpay_public_key,
        default_payment_gateway == 'razorpay' ? true : false
      ),
      spacing: 4,
      hidden: default_payment_gateway !== 'razorpay',
    },
    {
      ...textField(
        'razorpay_secret_key',
        'Razorpay Secret Key',
        'Enter Razorpay Secret Key',
        data?.payment_gateway?.razorpay?.razorpay_secret_key,
        default_payment_gateway == 'razorpay' ? true : false
      ),
      spacing: 4,
      type: 'password',
      hidden: default_payment_gateway !== 'razorpay',
    },
    {
      ...textField(
        'razorpay_redirection_url',
        'Razorpay Redirection Url',
        'Enter Razorpay Redirection Url',
        data?.payment_gateway?.razorpay?.razorpay_redirection_url,
        default_payment_gateway == 'razorpay' ? true : false
      ),
      spacing: 4,
      hidden: default_payment_gateway !== 'razorpay',
    },

    {
      ...textField(
        'easebuzz_merchant_key',
        'Easebuzz Merchant Key',
        'Enter Easebuzz Merchant Key',
        data?.payment_gateway?.easebuzz?.easebuzz_merchant_key,
        default_payment_gateway == 'easebuzz' ? true : false
      ),
      spacing: 4,
      type: 'password',
      hidden: default_payment_gateway !== 'easebuzz',
    },
    {
      ...textField(
        'easebuzz_salt',
        'Easebuzz salt',
        'Enter Easebuzz salt',
        data?.payment_gateway?.easebuzz?.easebuzz_salt,
        default_payment_gateway == 'easebuzz' ? true : false
      ),
      spacing: 4,
      type: 'password',
      hidden: default_payment_gateway !== 'easebuzz',
    },
    {
      ...textField(
        'easebuzz_redirection_url',
        'Easebuzz Redirection Url',
        'Enter Easebuzz Redirection Url',
        data?.payment_gateway?.easebuzz?.easebuzz_redirection_url,
        default_payment_gateway == 'easebuzz' ? true : false
      ),
      spacing: 4,
      hidden: default_payment_gateway !== 'easebuzz',
    },
    {
      type: 'checkbox',
      label: 'Enable Split Payment',
      name: 'easebuzz_split_payment_enabled',
      hidden: default_payment_gateway !== 'easebuzz',
      value: data?.payment_gateway?.easebuzz?.easebuzz_split_payment_enabled,
      spacing: 4,
    },
    {
      type: 'checkbox',
      label: 'Enable Split Payment',
      name: 'razorpay_split_payment_enabled',
      hidden: true,
      // hidden: default_payment_gateway !== 'razorpay',
      value: data?.payment_gateway?.razorpay?.razorpay_split_payment_enabled,
      spacing: 4,
    },
  ]

  if (isEditing) {
  }

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
  }
  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    setIsEditing(!isEditing)
  }
  const { mutate } = useUpdatePaymentGateway(handleSubmission)

  const onSubmit = (data: any) => {
    if (default_payment_gateway == 'razorpay') {
      mutate({
        input: {
          default_payment_gateway: data.default_payment_gateway,
          razorpay_public_key: data.razorpay_public_key,
          razorpay_secret_key: data.razorpay_secret_key,
          razorpay_redirection_url: data.razorpay_redirection_url,
          razorpay_split_payment_enabled: data?.razorpay_split_payment_enabled,
          easebuzz_merchant_key: null,
          easebuzz_salt: null,
          easebuzz_redirection_url: null,
          easebuzz_split_payment_enabled: undefined,
        },
        id,
      })
    } else if (default_payment_gateway == 'easebuzz') {
      mutate({
        input: {
          default_payment_gateway: data.default_payment_gateway,
          easebuzz_merchant_key: data.easebuzz_merchant_key,
          easebuzz_salt: data.easebuzz_salt,
          easebuzz_redirection_url: data.easebuzz_redirection_url,
          easebuzz_split_payment_enabled: data?.easebuzz_split_payment_enabled,
          razorpay_public_key: null,
          razorpay_secret_key: null,
          razorpay_redirection_url: null,
          razorpay_split_payment_enabled: undefined,
        },
        id,
      })
    }
  }

  const { handleSubmit } = methods

  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder
              data={formBuilderProps}
              edit={isEditing}
              spacing={true}
            />
          </FormProvider>
        ) : (
          formBuilderProps.map((basicInformation: any) =>
            basicInformation.name == 'default_payment_gateway_name' ? (
              <div key={data?.id} className=" col-span-12 md:col-span-12">
                <FormFieldView
                  value={basicInformation.value}
                  required={basicInformation?.required}
                  label={basicInformation.label}
                  link={basicInformation?.link}
                  hidden={basicInformation?.hidden}
                  type={basicInformation?.type}
                />
              </div>
            ) : (
              <div
                key={data?.id}
                className=" col-span-12 md:col-span-6 xl:md:col-span-4"
              >
                <FormFieldView
                  value={basicInformation.value}
                  required={basicInformation?.required}
                  label={basicInformation.label}
                  link={basicInformation?.link}
                  type={basicInformation?.type}
                  hidden={basicInformation?.hidden}
                />
              </div>
            )
          )
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="location-pin" />
          <h4 className=" font-bold text-primaryText">Payment gateway</h4>
        </div>
        <div className="iconBlack cursor-pointer">
          {hasPermission && (
            <div className="iconBlack cursor-pointer">
              {isEditing ? (
                <div className="flex gap-2">
                  <div>
                    <Button
                      onClick={handleCancel}
                      size="xs"
                      label="Cancel"
                      outlined={true}
                    />
                  </div>
                  <div>
                    <Button
                      size="xs"
                      label="Save"
                      outlined={false}
                      onClick={handleSubmit((data) => onSubmit(data))}
                    />
                  </div>
                </div>
              ) : !editFlag ? (
                <Icons onClick={handleEditClick} name="edit" />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">{renderFields()}</div>
    </div>
  )
}

export default BasicInformation
