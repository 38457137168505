import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { getService } from '../../../apis/common.apis'
import FormBuilder from '../../../components/app/formBuilder/index'
import CustomDrawer from '../../../components/common/drawer'
import { useSnackbarManager } from '../../../components/common/snackbar'
import { useAuthStore } from '../../../store/authStore'
import {
  getErrorMessage,
  getServiceTotal,
  numberFormat,
} from '../../../utilities/parsers'
import { addService, updateQuoteService } from '../api'
import { serviceItemSchema, ServiceItemSchema } from '../create/quoteSchema'

type Props = {
  isDrawerOpen: boolean
  isEditOpen?: boolean
  serviceData?: any
  handleClose: () => void
  subSection?: boolean
  handleCallback?: () => void
  id?: string
}

export default function Create({
  isDrawerOpen,
  isEditOpen,
  serviceData,
  handleClose,
  handleCallback,
  subSection,
  id,
}: Props) {
  const [servicePage, setServicePage] = useState(1)
  const { enqueueSnackbar } = useSnackbarManager()
  const franchisee = useAuthStore.getState().franchisee
  const [isLoading, setIsLoading] = useState(false)

  const onInit = () => {
    // if (isEditOpen && serviceData) {
    //   return {
    //     rounding: null,
    //     service_total: null,
    //   }
    // } else {
    return {
      rounding: null,
      service_total: null,
    }
    // }
  }

  const methods = useForm<ServiceItemSchema>({
    resolver: zodResolver(serviceItemSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })

  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isEditOpen && serviceData) {
      methods.reset({
        service_id: serviceData?.service?.id,
        service_name: serviceData?.service?.service_name,
        professional_fee: +serviceData?.professional_fee,
        vendor_fee: +serviceData?.vendor_fee,
        govt_fee: +serviceData?.govt_fee,
        gst_percent: +serviceData?.gst_percent,
        discount_percent: +serviceData?.discount_percent,

        rounding: serviceData?.price_adjustment,
        service_total: serviceData?.total_price,
      })
    }
  }, [isEditOpen, serviceData])

  const getServiceList = async (search?: string, page?: number) => {
    const { data } = await getService({
      search: search as string,
      page: page as number,
    })
    const next = data.next ? servicePage + 1 : 0
    setServicePage(next)

    return data?.results
  }

  const getRounding = (value: any) => {
    if (!value) {
      return 0.0
    }
    const parsedValue = parseFloat(value)
    if (isNaN(parsedValue)) {
      return NaN
    }
    const rounded_total: number = Math.ceil(parsedValue)
    const rounding = rounded_total - value
    return rounding
  }

  useEffect(() => {
    methods.setValue(
      'rounding',
      numberFormat(
        getRounding(methods.watch()?.professional_fee) +
          getRounding(methods.watch()?.govt_fee) +
          // getRounding(methods.watch()?.gst_percent)+
          // getRounding(methods.watch()?.discount_percent)+
          getRounding(methods.watch()?.vendor_fee as number)
      )
    )

    methods.setValue(
      'service_total',
      numberFormat(
        // parseFloat(
        getServiceTotal(
          methods.watch()?.professional_fee,
          methods.watch()?.govt_fee,
          methods.watch()?.gst_percent,
          methods.watch()?.discount_percent,
          methods.watch()?.vendor_fee as number
        )
        // ).toFixed(0)
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods?.watch().professional_fee,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch()?.govt_fee,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch()?.gst_percent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch()?.discount_percent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch()?.vendor_fee as number,
  ])

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    type = 'text',
    disabled = false
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
    ...(disabled ? { disabled: true } : {}),
  })

  const formBuilderProps = [
    {
      name: 'service_name',
      label: 'Service Name',
      required: true,
      getData: getServiceList,
      async: true,
      id: 'service_id',
      paginationEnabled: true,
      nextBlock: servicePage,
      descId: 'id',
      initialLoad: true,
      disabled: false,
      desc: 'service_name',
      type: 'auto_complete',
      placeholder: 'Enter Service Name',
    },
    textField(
      'professional_fee',
      'Professional Fee',
      'Enter Professional Fee',
      true,
      'number'
    ),
    textField('vendor_fee', 'Vendor Fee', 'Enter Vendor Fee', false, 'number'),
    textField(
      'govt_fee',
      'Government Fee',
      'Enter Government Fee',
      true,
      'number'
    ),
    ...(franchisee?.has_gst
      ? [
          textField(
            'gst_percent',
            'GST Percentage',
            'Enter GST Percentage',
            true,
            'number'
          ),
        ]
      : []),
    textField(
      'discount_percent',
      'Discount Percentage',
      'Enter Discount Percentage',
      true,
      'number'
    ),
    textField('rounding', 'Rounding', 'Enter Rounding', false, 'text', true),
    textField(
      'service_total',
      'Service Total',
      'Enter Service Total',
      false,
      'text',
      true
    ),
  ]

  const handleSubmission = () => {
    methods.reset()
    handleClose()
    handleCallback?.()
  }

  const onSubmit = (data: ServiceItemSchema) => {
    setIsLoading(true)
    const greaterThanOne = true
    // const val = getServiceTotal(
    //   data.professional_fee,
    //   data.govt_fee,
    //   data.gst_percent,
    //   data.discount_percent,
    //   data?.vendor_fee as number
    // )
    // if (Number(val) <= 0) {
    //   greaterThanOne = false
    // }
    if (!greaterThanOne) {
      enqueueSnackbar('Service total must be greater than 0', {
        variant: 'error',
      })
      return false
    } else {
      const input =
        serviceData && serviceData?.id
          ? {
              discount_percent: data.discount_percent,
              govt_fee: data.govt_fee,
              gst_percent: data.gst_percent,
              professional_fee: data.professional_fee,
              vendor_fee: data.vendor_fee ?? 0,
              service_id: data.service_id,
            }
          : {
              items: [
                {
                  discount_percent: data.discount_percent,
                  govt_fee: data.govt_fee,
                  gst_percent: data.gst_percent,
                  id: data.service_id ?? '',
                  professional_fee: data.professional_fee,
                  vendor_fee: data.vendor_fee ?? 0,
                },
              ],
            }
      const apiFunc =
        serviceData && serviceData?.id
          ? updateQuoteService(serviceData?.id, {
              input: input,
              id: id ? id : '',
            })
          : addService({ input: input, id: id ? id : '' })

      apiFunc
        .then(() => {
          enqueueSnackbar(
            getErrorMessage(
              serviceData && serviceData?.id
                ? 'Service Updated Successfully'
                : 'Service Created successfully'
            ),
            {
              variant: 'success',
            }
          )
          handleSubmission()
          setIsLoading(false)
        })
        .catch((err) => {
          enqueueSnackbar(getErrorMessage(err.response.data.error), {
            variant: 'error',
          })
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    if (subSection) handleCallback?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    handleClose()
  }
  useEffect(() => {
    methods.reset({
      service_id: '',
      service_name: '',
      professional_fee: undefined,
      vendor_fee: undefined,
      govt_fee: undefined,
      gst_percent: undefined,
      discount_percent: undefined,
    })
  }, [isDrawerOpen && !isEditOpen])
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      actionLoader={isLoading}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
      title={isEditOpen ? 'Edit Service' : 'Add New Service'}
      disableSubmit={isLoading}
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder data={formBuilderProps} edit={true} />
        </FormProvider>
      </div>
    </CustomDrawer>
  )
}
